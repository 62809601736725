var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    _vm._g(
      _vm._b(
        {
          staticClass: "timeline-item",
          class: [
            "timeline-variant-" + _vm.variant,
            _vm.fillBorder ? "timeline-item-fill-border-" + _vm.variant : null
          ]
        },
        "li",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      !_vm.icon
        ? _c("div", { staticClass: "timeline-item-point" })
        : _c(
            "div",
            {
              staticClass:
                "timeline-item-icon d-flex align-items-center justify-content-center rounded-circle"
            },
            [_c("feather-icon", { attrs: { icon: _vm.icon } })],
            1
          ),
      _vm._t("default", [
        _c(
          "div",
          {
            staticClass:
              "d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
          },
          [
            _c("h6", { domProps: { textContent: _vm._s(_vm.title) } }),
            _c("small", {
              staticClass: "timeline-item-time text-nowrap text-muted",
              domProps: { textContent: _vm._s(_vm.time) }
            })
          ]
        ),
        _c("p", {
          staticClass: "mb-0",
          domProps: { textContent: _vm._s(_vm.subtitle) }
        })
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }